import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CookieConsentService } from '../shared/services/cookie-consent.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-adsense-ad',
  templateUrl: './adsense-ad.component.html',
  styleUrls: ['./adsense-ad.component.scss']
})
export class AdsenseAdComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() adHeight: number = 250;
  @Input() adWidth: number = 300;
  showAds: boolean = false;
  adClient: string = environment.adsense.adClient;
  uniqueId: string;

  private subscription!: Subscription;

  constructor(private cookieConsentService: CookieConsentService) {
    this.uniqueId = `adsense-ad-${new Date().getTime()}-${Math.random().toString(36).substring(2, 9)}`;
  }

  ngOnInit(): void {
    this.cookieConsentService.loadAdsenseScript();

    this.subscription = this.cookieConsentService.scriptLoaded$.subscribe(loaded => {
      if (loaded) {
        this.showAds = true;
        this.injectAd()
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
      this.injectAd();
  }

  injectAd(): void {
    const adContainer = document.createElement('ins');
    adContainer.className = 'adsbygoogle adsense-container-child';
    adContainer.style.display = 'block';
    adContainer.setAttribute('data-ad-client', this.adClient);
    adContainer.setAttribute('data-ad-slot', '7259870550');
    adContainer.setAttribute('data-ad-format', 'auto');
    adContainer.style.height = `${this.adHeight}px`;
    adContainer.style.width = `${this.adWidth}px`;
    adContainer.setAttribute('data-ad-format', 'auto');
    const adElement = document.getElementById(this.uniqueId);
    if (adElement) {
      adElement.appendChild(adContainer);
      const windowWithAds = window as any;
      if (windowWithAds.adsbygoogle) {
        (windowWithAds.adsbygoogle = windowWithAds.adsbygoogle || []).push({});
      }
    }
  }
}