import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { environment } from '../../../environments/environment';
import { CookieConsentService } from './cookie-consent.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  private analytics: any;

  constructor( private cookieConsentService: CookieConsentService, ){}

  loadFirebase(): void {
    console.log("firebase")
    const app = initializeApp(environment.firebase);
    this.analytics = getAnalytics(app);
  }

  callLogEvent(eventName: string, eventParams?: { [key: string]: any }) {
    const consent = this.cookieConsentService.getConsent()
    if (consent) {
      logEvent(this.analytics, eventName, eventParams);
    }
  }
}